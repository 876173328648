<template>
  <div class="authority">
    <div class="page" style="background: #ffffff; padding: 20px; color: #424042">
      <h1 class="title">隐私权限</h1>
      <p>
        解压大师（或“我们”）从您那里收集信息，以便提供更好的服务并为您提供更好的用户体验。我们始终将您的隐私作为首要考虑因素，并且隐私政策解释了我们如何收集，使用和保护您的信息。您可以随时在我们的“应用程序设置”中更改您的同意。
      </p>
      <br />

      <p>
        1.数据负责人及代表 道诚科技及其子公司*（“道诚科技”或“我们”或“我们的”或“我们的”）关心您的个人信息的隐私并致力于保护您的个人信息。我们可能必须收集您的个人数据，才能为您提供最好的武器和最新的安全性。我们不会把您的信任视为理所当然。因此，在我们的隐私权政策中，我们解释了我们的工作方式，操作方式以及您的选择，以及我们可能如何需要您的合作以帮助您保持安全。
      </p>
      <br />

      <p>2.我们收集什么样的信息 在提供应用程序时，我们收集，存储和使用与您有关的以下信息：</p>
      <br />
      <p>
        2.1 个人信息“个人信息”是识别您或另一个人的信息。个人信息可能包括以下内容：<br />
        <br />
        当您开设解压大师帐户或使用其他服务时向我们提供的信息，例如您的姓名，电话号码，电子邮件地址； 您公开可见的应用程序配置文件中包含的任何信息，其中可能包括您的配置文件ID，名称和照片； 您通过使用应用程序和/或您用于开设您的解压大师帐户的其他服务向我们和/或公众提供的信息； 另一个用户通过使用“应用程序”提供的有关您的信息； 我们在您使用应用程序时收集的信息，例如某些位置数据和日志数据。 “位置数据”是我们收集的有关您的位置的信息（当您使用启用了位置的服务时），包括：<br />
        <br />
        使用应用程序时设备的位置，例如来自GPS，Wi-Fi，指南针，加速度计或移动设备中的其他传感器的位置；您用于访问应用程序的设备或Internet服务的IP地址；您或其他人提供的其他有关您的位置或位置的信息，例如指示您的位置的帐户信息以及您或其他人发布的指示您的位置的共享信息。<br />
        <br />
        “日志数据”是我们在使用应用程序时通过使用cookie，网络信标，日志文件，脚本和电子标签或其他方式自动收集的技术信息，包括：技术信息，例如您的移动运营商-相关信息，您的Web浏览器或用于访问应用程序的其他程序提供的配置信息，您的IP地址以及您设备的版本和标识号； 有关您在使用应用时搜索和查看的内容的信息，例如使用的Web搜索术语，访问的社交媒体资料以及您在使用应用时访问或请求的其他信息和内容的详细信息；<br />
        <br />
        有关您使用应用程序进行的通信的信息，例如与您通信的人员以及通信的时间，数据和持续时间；元数据，即与您通过应用程序提供的ps有关的信息，例如拍摄或发布共享照片或视频的日期，时间或位置。<br />
        <br />
        2.2 非个人信息 非个人信息是与您有关但无法直接或间接识别您的任何信息，其中可能包括有关您设备的信息（例如您的硬件型号，操作系统版本，唯一设备标识符和移动设备）网络信息），您设备上已安装应用的列表以及有关用户与我们应用的连接方式的技术信息（例如手机型号的版本和其他类似信息）。
        <br />
      </p>
      <br />

      <p>3.我们如何使用收集的信息</p>
      <br />

      <p>3.1 我们可能出于以下任何目的使用您的信息：<br />
        向您提供应用程序和服务（以及应用程序中的功能），以向其他用户显示，例如您所连接的朋友或平台上的其他用户； 为您提供与您更相关的广告和直接营销； 更好地了解您如何访问和使用应用程序，以便我们改进应用程序并响应客户的需求和喜好，包括为我们提供语言和位置自定义，个性化的帮助和说明，或对您和其他客户对产品使用的其他回应应用; 帮助开发我们的服务，并提供，维护，改进和修改应用程序，服务和站点以及开发新服务； 评估应用程序或与之相关的广告以及其他营销和促销活动的有效性，并改善其效果。 </p>
      <br />
      <p>3.2 如果您的任何个人信息包含您的内容（在服务条款中定义），则我们和我们的关联公司可以（根据本隐私政策）根据以下条款的“您的内容”部分使用此类个人信息。服务。</p>
      <br />
      <p>3.3 我们收集的数据取决于您与我们互动的背景，您所做的选择（包括您的隐私设置）以及您使用的产品和功能。我们收集的数据可以包括SDK / API / JS代码版本，浏览器，Internet服务提供商，IP地址，平台，时间戳，应用程序标识符，应用程序版本，应用程序分发渠道，独立的设备标识符，iOS广告标识符（IDFA），Android广告主标识符，网卡（MAC）地址和国际移动设备识别码（IMEI）设备型号，终端制造商，终端设备操作系统版本，会话开始/停止时间，语言的位置，时区以及网络状态（WiFi等），硬盘，CPU和电池使用情况等。</p>
      <br />
      <p>3.4 为了改进我们的产品并为您提供更好的服务，我们还将与代表我们工作的供应商或代理商共享个人数据，以达到本隐私政策中所述的目的。例如，我们雇用来提供数据分析服务的公司可能需要收集和访问个人数据才能提供这些功能。在这种情况下，这些公司必须遵守我们的数据隐私和安全要求。</p>
      <br />
      <p>3.5 如上所述，我们出于本隐私政策中规定的目的处理个人数据。我们处理个人数据的法律依据包括以下处理：与您履行合同所必需的（例如，向您提供您请求的服务以及识别和认证您，以便您可以使用网站）；必须遵守法律要求（例如，遵守适用的会计规则并向执法部门强制披露）；为了我们的合法利益（例如，管理我们与您的关系，确保我们的服务的安全性，与您就我们的产品和服务进行沟通）所必需；并获得我们客户的同意（例如，出于广告目的放置某些Cookie并与第三方共享您的信息）。在某些情况下，可能需要您向我们提供个人数据以进行如上所述的处理，以便我们能够为您提供我们的所有服务，并让您使用我们网站的所有功能。</p>
      <br />
      <p>3.6 在适用法律的限制下，您有权反对或要求限制您的个人数据的处理，并有权要求访问，更正，擦除和携带您的个人数据。<br />
        <br />
        如果您的信息使用是基于同意的，则您可以随时撤回此同意，而不会影响撤回之前基于同意的处理的合法性。应当与我们联系来提交请求。<br />
        <br />
        如果您知道自己信息的更改或不正确，则应将此类更改告知我们，以便我们的记录可以更新或更正。如果您认为我们处理您的个人数据违反了适用法律，则可以向监管机构投诉。<br />
        <br />
        只要为您提供服务或产品所需，或者根据适用法律（例如税法和会计法）的要求或许可，我们将保留您的个人数据。<br />
      </p>
      <br />

      <p>4.如何披露您的信息</p>
      <br />
      <p>4.1 未经您的同意，除以下情况外，我们不会将您的信息透露给任何第三方： <br />
        经您事先授权或同意； 在政府部门的强制性要求下或根据法律，法规，规章和法律程序； 为了学术研究或公共利益的目的； 维护我们的合法权益，例如寻找，捍卫和解决侵权或安全问题； 选择共享功能时； 根据服务条款和隐私政策的其他情况。</p>
      <br />
      <p>4.2我们和我们的关联公司可能会在我们的公司集团内以及与合资伙伴和第三方服务提供商，营销伙伴，承包商和代理商共享您的个人信息，分别用于以下目的：（i）为您提供服务; （ii）协助我们执行以上“我们如何使用您的信息”部分规定的目的； （iii）履行我们在服务条款或本隐私政策下的义务并执行我们的权利；和/或（iv）帮助我们了解和改善服务。</p>
      <br />

      <p>5.第三方</p>
      <br />

      <p>5.1 我们可能会提供指向第三方网站的链接。该网站还可能载有其他公司的广告。当您单击应用程序，服务或站点中的第三方链接时，您可以离开应用程序，服务或站点。我们不会在其上或通过其收集您的信息，并且本隐私政策不会解决由第三方运营的网站的隐私惯例，并且不对它们负责，无论它们是链接到应用程序，服务或网站。包含第三方网站的链接或可访问性并不意味着我们认可此类第三方网站。</p>
      <br />
      <p>5.2 您通过应用程序，服务和/或站点传输，发送和/或共享的信息可能会被第三方拦截，收集，使用和披露。对于任何第三方截取，收集，使用和披露您的信息，我们概不负责。如果您对安全性有更高的要求，请选择其他传输和共享方式。</p>
      <br />

      <p>6.帐户安全。请不要购买，出售，转让，出租和/或将您的帐户和/或密码出租给他人。对于因黑客入侵或疏忽密码而导致他人非法使用您的帐户，我们概不负责。</p>
      <br />

      <p>7.安全性。我们非常关注保护您信息的机密性。我们采取行政，物理和电子措施，旨在保护您的信息免遭未经授权的访问和使用。请注意，我们绝对不能保证采取任何安全措施来保护您的信息，以避免未经许可的访问或使用您的信息。</p>
      <br />

      <p>8.国际用户。您的信息可能会在我们拥有设施的任何国家/地区中存储和处理，并且通过使用应用程序，服务或网站，您同意将您的信息传输到您所在国家/地区以外的国家/地区，包括美国。居住，并且可能会规定与您所在国家/地区不同且宽松程度较低的数据保护规则。如果您反对如本政策所述转移或使用您的信息，请不要使用任何应用程序，服务或网站，并立即从用户设备中删除所有应用程序。</p>
      <br />

      <p>9.敏感信息。我们要求您不要发送给我们，也不要透露任何敏感的个人信息（例如，与种族或民族血统，政治见解，宗教或其他信仰，健康，性取向，犯罪背景或在过去的组织中的成员身份有关的信息工会会员资格）通过应用程序，服务或网站或通过其他方式获得。</p>
      <br />

      <p>10.儿童信息。解压大师不会有意收集13岁以下儿童（或适用法律所定义的法律规定的少年同意的法定年龄）的任何个人身份信息，或有意地允许该人在解压大师上注册。如果我们被告知未经父母同意，我们从13岁以下的孩子（或适用法律所定义的法律规定的青少年同意年龄）中收集了个人信息，我们将暂停帐户并删除相关信息尽快。如果您认为我们可能从13岁以下的儿童（或适用法律所定义的法律规定的青少年同意年龄）中获取任何信息，请与我们联系。</p>
      <br />

      <p>11.与我们联系 如果您对此政策或我们的隐私惯例有任何疑问或意见，或报告任何违反本政策或滥用应用程序，服务或网站的情况，请我们联系。</p>
      <br />

      <p>12.本隐私政策的变更 我们可能会不时修订本隐私政策。 在这些更改生效后继续访问或使用服务，即表示您同意受修订的隐私政策的约束。</p>
      <br />
      <h1 class="title">软件使用权限及使用说明</h1>
      <p>一、所需权限及说明</p><p>根据您的行为及产品特点，解压大师将基于以下目的访问您的个人信息及设备的存储，开通相应权限：</p><p>1）访问设备的存储；</p><p>获取设备的存储访问权限，为您实现文件的压缩与压缩文件的解压。</p><p>2）获取设备通知权限；</p><p>在感知到新下载压缩文件或有更新版本时，及时通知您获知，以获得更好的用户体验。</p><p>二、权限的关闭及再次开启</p><p>已经对解压大师开启的权限，可以随时在设备设置里面关闭或再次开启。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Authority',
};
</script>

<style scoped lang="less">
  .authority {
    .title {
      margin-bottom: 20px;
    }
    p {
      font-size: 18px;
    }
  }
</style>
