<template>
  <div class="six-screen">
    <h1 class="title">{{ $t(title) }}</h1>
    <p class="desc">{{ $t(description) }}</p>
    <div class="button-box">
      <button
        class="left-button button"
        :class="{ 'en-button': language === 'en' }"
        data-agl-cvt="6"
        @click="downloadDirectly">
        <img :src="$t(button_download)" alt="Download">
        <span class="button-text">{{ $t(free_download) }}</span>
      </button>
      <button
        class="right-button button"
        @click="jumpToAppStore">
        <img :src="$t(button_apple)" alt="Apple download">
        <span class="button-text">
          <span class="app-store-text">{{ $t(app_store_text) }}</span>
          <span class="download-text">{{ $t(download_text) }}</span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import languageMixin from './mixin/languageMixin';
import jumpMixin from './mixin/jumpMixin';

export default {
  name: 'SixScreen',
  mixins: [languageMixin, jumpMixin],
  data() {
    return {
      title: 'bottom_title',
      description: 'bottom_desc',
      free_download: 'free_download',
      app_store_text: 'app_store',
      download_text: 'download',
      button_apple: 'button_apple',
      button_download: 'button_download',
    };
  },
};
</script>

<style scoped lang="less">
  .six-screen {
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    .title, .desc {
      text-align: center;
    }
    .title {
      margin: 1rem 0 0.12rem;
      font-size: 0.56rem;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 0.78rem;
    }
    .desc {
      margin-bottom: 0.4rem;
      font-size: 0.21rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 0.29rem;
    }
    .button-box {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
      .button {
        width: 2.22rem;
        height: 0.64rem;
        margin-right: 0.16rem;
        border-radius: 6px;
        cursor: pointer;
        /*img, .button-text {*/
        /*  vertical-align: middle;*/
        /*}*/
        img {
          height: 60%;
          margin-right: 0.18rem;
        }
        .button-text {
          font-size: 0.24rem;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 0.33rem;
        }
      }
      .left-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(to right, #FE552F, #FF8B37);
        img {
          width: 0.38rem;
          height: 0.38rem;
        }
      }
      .en-button {
        img {
          margin-right: 0.1rem;
        }
        .button-text {
          font-size: 0.21rem;
        }
      }
      .right-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(to right, #DADADA, #FFFFFF);
        img {
          height: 0.46rem;
          margin-right: 0.14rem;
        }
        .button-text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          color: #1A1A1A;
          .app-store-text {
            font-size: 0.2rem;
            font-weight: 500;
            color: #1A1A1A;
            line-height: 0.28rem;
          }
          .download-text {
            font-size: 0.14rem;
            line-height: 0.2rem;
          }
        }
      }
    }
  }
</style>
