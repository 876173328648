import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ba from 'vue-ba';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils/flexlib';
import zh from './lang/zh.json';
import en from './lang/en.json';
import getLanguage from './utils/getLanguage';
import baiduPlugin from './plugins/baiduPlugin';

Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(ba, { siteId: '98dbff9d94aa035e443fd5e4b6c58a60' });
Vue.use(baiduPlugin);

const messages = {
  en,
  zh,
};
const i18n = new VueI18n({
  locale: getLanguage(),
  messages,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
