<template>
  <div class="third-screen">
    <h1 class="title">{{ $t(title) }}</h1>
    <div class="question-box">
      <div
        class="question-item"
        v-for="(item) in question_list"
        :key="item.text">
        <img class="img" :src="$t(item.img)" alt="Question image">
        <span class="text">{{ $t(item.text) }}</span>
      </div>
      <img
        class="question-img"
        :src="$t(pic_people)"
        alt="People image">
    </div>
    <div class="bottom-box">
      <span class="bottom-text">{{ $t(bottom_text) }}</span>
      <button class="download-button" data-agl-cvt="6" @click="downloadDirectly">
        <span class="button-wrap">
          <img
            class="button-img"
            :src="$t(button_download)"
            alt="Download image">
          <span class="button-text">{{ $t(download_text) }}</span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
// import img1 from '../../public/image/pic_pop_one@2x.png';
// import img2 from '../../public/image/pic_pop_two@2x.png';
// import img3 from '../../public/image/pic_pop_three@2x.png';
// import img4 from '../../public/image/pic_pop_four@2x.png';
import jumpMixin from './mixin/jumpMixin';

export default {
  name: 'ThirdScreen',
  mixins: [jumpMixin],
  data() {
    return {
      title: 'question_title',
      question_list: [
        {
          // img: img1,
          img: 'pic_pop_one',
          text: 'question_1',
        },
        {
          // img: img2,
          img: 'pic_pop_two',
          text: 'question_2',
        },
        {
          // img: img3,
          img: 'pic_pop_three',
          text: 'question_3',
        },
        {
          // img: img4,
          img: 'pic_pop_four',
          text: 'question_4',
        },
      ],
      bottom_text: 'question_bottom',
      download_text: 'download_immediately',
      button_download: 'button_download',
      pic_people: 'pic_people',
    };
  },
};
</script>

<style scoped lang="less">
  .third-screen {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    /*overflow: hidden;*/
    .title {
      margin: 0 0 1.94rem;
      padding-top: 1.2rem;
      font-size: 0.4rem;
      font-weight: 500;
      color: #1A1A1A;
      line-height: 0.56rem;
      text-align: center;
    }
    .question-box {
      display: inline-block;
      position: relative;
      width: 8.92rem;
      height: 4.46rem;
      margin-bottom: 0.72rem;
      text-align: center;
      .question-img {
        width: 100%;
        height: 100%;
      }
      .question-item:nth-child(1) {
        position: absolute;
        top: -1.31rem;
        left: 0.9rem;
        .img {
          width: 2.74rem;
          height: 2.44rem;
        }
      }
      .question-item:nth-child(2) {
        position: absolute;
        top: -1.65rem;
        right: 0.27rem;
        .img {
          width: 3.06rem;
          height: 2.62rem;
        }
      }
      .question-item:nth-child(3) {
        position: absolute;
        top: 2.11rem;
        left: -1.35rem;
        .img {
          width: 2.68rem;
          height: 1.72rem;
        }
      }
      .question-item:nth-child(4) {
        position: absolute;
        top: 2.14rem;
        right: -1.5rem;
        .img {
          width: 2.82rem;
          height: 1.66rem;
        }
      }
      .text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 2.54rem;
        font-size: 0.21rem;
        font-weight: 400;
        color: #1A1A1A;
        line-height: 0.29rem;
        white-space: pre-wrap;
      }
    }
    .bottom-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 90%;
      max-width: 1200px;
      height: 1.44rem;
      transform: translate(-50%, 50%);
      padding: 0 0.56rem;
      background: linear-gradient(to right, #FE552F, #FF8B37);
      border-radius: 0.12rem;
      .bottom-text {
        margin: 0 0.24rem;
        font-size: 0.32rem;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 0.45rem;
        text-align: left;
      }
      .download-button {
        width: 2.22rem;
        height: 0.64rem;
        background: linear-gradient(to right, #1B0C02, #4F260B);
        border-radius: 0.06rem;
        text-align: center;
        cursor: pointer;
        .button-wrap {
          vertical-align: middle;
        }
        .button-img, .button-text {
          vertical-align: middle;
        }
        .button-img {
          height: 0.38rem;
          margin-right: 0.18rem;
        }
        .button-text {
          font-size: 0.24rem;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 0.33rem;
        }
      }
    }
  }
</style>
