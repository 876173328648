import { render, staticRenderFns } from "./SixScreen.vue?vue&type=template&id=0a0bd7f0&scoped=true&"
import script from "./SixScreen.vue?vue&type=script&lang=js&"
export * from "./SixScreen.vue?vue&type=script&lang=js&"
import style0 from "./SixScreen.vue?vue&type=style&index=0&id=0a0bd7f0&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a0bd7f0",
  null
  
)

export default component.exports