import VersionDetail from '../../public/json/VersionDetail.json';

const channels = [
  'baidu20091501',
  'baidu21031001',
  'baidu21031201',
  'sogou21032901',
  '36021040201',
  'baidu21041301',
  'baidu21052601',
  'baidu21060901',
  // 以下为测试用
  'baidu123',
  'google888',
];
const version = 1;

export function getAppStoreUrl(ct) {
  if (channels.includes(ct)) {
    return `https://apps.apple.com/app/apple-store/id1492424532?pt=8024801&ct=${ct}&mt=8`;
  }
  return 'https://apps.apple.com/app/apple-store/id1492424532?pt=8024801&ct=portal&mt=8';
}

// eslint-disable-next-line no-unused-vars
export function getDownloadUrl(channel, cid) {
  // let dir = 'original';
  // if (channels.includes(`${channel}${cid}`)) {
  //   dir = `ch${channel}_cid${cid}`;
  // }
  // return `https://zipmaster-cdn.doviapps.com/download/${dir}/解压大师_v${VersionDetail.file_version}.dmg?v=${version}`;
  return `https://zipmaster-cdn.doviapps.com/download/original/解压大师_v${VersionDetail.file_version}.dmg?v=${version}`;
}
