<template>
  <div class="home">
    <div class="nav-wrap wrap" ref="nav_wrap">
      <Nav></Nav>
    </div>
    <div class="first-screen-wrap" :style="`background-image: url('${$t(bg_banner)}')`">
      <first-screen></first-screen>
    </div>
    <div class="second-screen-wrap wrap">
      <second-screen></second-screen>
    </div>
    <div class="third-screen-wrap wrap">
      <third-screen></third-screen>
    </div>
    <div class="forth-screen-wrap wrap">
      <forth-screen></forth-screen>
    </div>
    <div class="fifth-screen-wrap wrap">
      <fifth-screen></fifth-screen>
    </div>
    <div class="footer-wrap wrap">
      <six-screen></six-screen>
      <div class="gap"></div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Nav from './Nav.vue';
import FirstScreen from './FirstScreen.vue';
import SecondScreen from './SecondScreen.vue';
import ThirdScreen from './ThirdScreen.vue';
import ForthScreen from './ForthScreen.vue';
import FifthScreen from './FifthScreen.vue';
import SixScreen from './SixScreen.vue';
import Footer from './Footer.vue';

export default {
  name: 'Home',
  components: {
    SixScreen,
    Footer,
    FifthScreen,
    ForthScreen,
    ThirdScreen,
    SecondScreen,
    FirstScreen,
    Nav,
  },
  data() {
    return {
      bg_banner: 'bg_banner',
    };
  },
  mounted() {
    const navScrollHandler = () => {
      const scrollHeight = document.documentElement.scrollTop
        || document.body.scrollTop;
      const navWrapElement = this.$refs.nav_wrap;
      const baseHeight = 600;
      const transparent = scrollHeight >= baseHeight ? 1 : scrollHeight / baseHeight;
      if (navWrapElement) {
        navWrapElement.style.backgroundColor = `rgba(27, 12, 2, ${transparent})`;
      }
    };
    document.addEventListener('scroll', navScrollHandler);
    const { query } = this.$route;
    this.$ba.trackEvent('pageView', `channel_${query.channel || 'organic'}`, `cid_${query.cid || 'organic'}`);
  },
};
</script>

<style scoped lang="less">
  @nav-height: 56px;
  .home {
    min-width: 756px;
    overflow: hidden;
    .nav-wrap {
      position: fixed;
      width: 100%;
      z-index: 99;
      background-color: rgba(27, 12, 2, 0);
    }
    .first-screen-wrap {
      /*min-height: 100vh;*/
      height: 9.66rem;
      margin-top: calc(~'-@{nav-height}');
      background-color: #F8F9FB;
      /*background-image: url("../../public/image/bg_banner@2x.png");*/
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
    }
    .second-screen-wrap {
      background-color: #F8F9FB;
    }
    .third-screen-wrap {
      background-color: #FFFFFF;
    }
    .forth-screen-wrap {
      background: linear-gradient(158deg, rgba(27, 12, 2, 0.9) 0%, #1B0C02 100%);
    }
    .fifth-screen-wrap {
      background: #FFFFFF;
    }
    .footer-wrap {
      background: linear-gradient(204deg, #1B0C02 0%, rgba(27, 12, 2, 0.9) 100%);
      .gap {
        border-top: 1.2px solid rgba(255, 255, 255, 0.2);
      }
    }
  }
</style>
