/* eslint-disable max-len */
import { getAppStoreUrl, getDownloadUrl } from '../../utils/getAppStoreUrl';

export default {
  methods: {
    jumpToAppStore() {
      const { query } = this.$route;
      this.$ba.trackEvent('appStoreDownload', `channel_click_${query.channel || 'organic'}`, `cid_${query.cid || 'organic'}`);
      const url = getAppStoreUrl(`${query.channel}${query.cid}`);
      window.open(url, '_blank');
    },
    downloadDirectly() {
      const { query } = this.$route;
      this.$ba.trackEvent('webDownload', `channel_click_${query.channel || 'organic'}`, `cid_${query.cid}`);
      window.open(getDownloadUrl(query.channel, query.cid));
      // window.open(this.$t(this.download_link));
    },
  },
};
