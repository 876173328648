<template>
  <div class="forth-screen">
    <h1 class="title">{{ $t(title) }}</h1>
    <div class="feature-box">
      <div class="left-box">
        <img
          class="img"
          :src="$t(pic_feature_one)"
          alt="Feature image">
      </div>
      <div class="right-box">
        <div class="feature-item" v-for="(item) in feature_list" :key="item.title">
          <img class="feature-img" :src="$t(item.img)" alt="Feature item image">
          <div class="feature-text">
            <h2 class="feature-title">{{ $t(item.title) }}</h2>
            <p class="feature-desc">{{ $t(item.description) }}</p>
          </div>
        </div>
        <div class="button-box">
          <button
            class="left-button button"
            :class="{ 'en-button': language === 'en' }"
            data-agl-cvt="6"
            @click="downloadDirectly">
            <img :src="$t(button_download)" alt="Download">
            <span class="button-text">{{ $t(free_download) }}</span>
          </button>
          <button
            class="right-button button"
            @click="jumpToAppStore">
            <img :src="$t(button_apple)" alt="Apple download">
            <span class="button-text">
              <span class="app-store-text">{{ $t(app_store_text) }}</span>
              <span class="download-text">{{ $t(download_text) }}</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import img1 from '../../public/image/ic_rightclick@2x.png';
// import img2 from '../../public/image/ic_compression@2x.png';
// import img3 from '../../public/image/ic_encryption@2x.png';
import languageMixin from './mixin/languageMixin';
import jumpMixin from './mixin/jumpMixin';

export default {
  name: 'ForthScreen',
  mixins: [languageMixin, jumpMixin],
  data() {
    return {
      title: 'feature_title',
      pic_feature_one: 'pic_feature_one',
      feature_list: [
        {
          img: 'right_click',
          title: 'feature_1_title',
          description: 'feature_1_desc',
        },
        {
          img: 'compression',
          title: 'feature_2_title',
          description: 'feature_2_desc',
        },
        {
          img: 'encryption',
          title: 'feature_3_title',
          description: 'feature_3_desc',
        },
      ],
      free_download: 'free_download',
      app_store_text: 'app_store',
      download_text: 'download',
      button_apple: 'button_apple',
      button_download: 'button_download',
    };
  },
};
</script>

<style scoped lang="less">
  .forth-screen {
    max-width: 1240px;
    margin: 0 auto;
    padding: 1.44rem 0.2rem 0;
    /*padding-top: 1.44rem;*/
    overflow: hidden;
    .title {
      margin-bottom: 0.88rem;
      font-size: 0.4rem;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 0.56rem;
      text-align: center;
    }
    .feature-box {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.84rem;
      .left-box {
        .img {
          width: 5.8rem;
          height: 4.56rem;
        }
      }
      .right-box {
        display: flex;
        flex-direction: column;
        margin: -0.2rem 0 0 0.57rem;
        .feature-item {
          display: flex;
          align-items: center;
          width: 5.23rem;
          height: 1.28rem;
          padding: 0 0.28rem;
          .feature-img {
            width: 0.72rem;
            height: 0.72rem;
            margin-right: 0.32rem;
          }
          .feature-text {
            .feature-title {
              font-size: 0.24rem;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 0.33rem;
              margin-bottom: 0.08rem;
            }
            .feature-desc {
              font-size: 0.16rem;
              font-weight: 400;
              color: rgba(255, 255, 255, 0.8);
              /*line-height: 0.22rem;*/
            }
          }
        }
        .button-box {
          display: flex;
          justify-content: center;
          margin-top: 0.32rem;
          .button {
            width: 2.22rem;
            height: 0.64rem;
            margin-right: 0.16rem;
            border-radius: 6px;
            cursor: pointer;
            /*img, .button-text {*/
            /*  vertical-align: middle;*/
            /*}*/
            img {
              height: 60%;
              margin-right: 0.18rem;
            }
            .button-text {
              font-size: 0.24rem;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 0.33rem;
            }
          }
          .left-button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: linear-gradient(to right, #FE552F, #FF8B37);
            img {
              width: 0.38rem;
              height: 0.38rem;
            }
          }
          .en-button {
            img {
              margin-right: 0.1rem;
            }
            .button-text {
              font-size: 0.21rem;
            }
          }
          .right-button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: linear-gradient(to right, #DADADA, #FFFFFF);
            img {
              height: 0.46rem;
              margin-right: 0.14rem;
            }
            .button-text {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              color: #1A1A1A;
              .app-store-text {
                font-size: 0.2rem;
                font-weight: 500;
                color: #1A1A1A;
                line-height: 0.28rem;
              }
              .download-text {
                font-size: 0.14rem;
                line-height: 0.2rem;
              }
            }
          }
        }
      }
    }
  }
</style>
