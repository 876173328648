<template>
  <div class="fifth-screen">
    <h1 class="title">{{ $t(title) }}</h1>
    <div class="comment-list">
      <div class="comment-item" v-for="(item) in comment_list" :key="item.author">
        <div class="top-overview">
          <div class="author-info">
            <img class="author-img" :src="$t(item.author_img)" alt="Author image">
            <span class="author-name">{{ $t(item.author_name) }}</span>
          </div>
          <div class="star-box">
            <img
              class="star-img"
              v-for="(img, index) in item.star_number"
              :key="`star${index}`"
              :src="$t(download_star)"
              alt="Star image">
            <img
              class="grey-star-img"
              v-for="(img, index) in 5 - item.star_number"
              :key="`grey-star${index}`"
              :src="$t(download_grey_star)"
              alt="Grey star image">
          </div>
        </div>
        <p class="comment-detail">{{ $t(item.detail) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
// import img1 from '../../public/image/pic_avatar1@3x.png';
// import img2 from '../../public/image/pic_avatar2@3x.png';
// import img3 from '../../public/image/pic_avatar3@3x.png';

export default {
  name: 'FifthScreen',
  data() {
    return {
      title: 'comment_title',
      comment_list: [
        {
          author_img: 'pic_avatar_1',
          author_name: 'user_1',
          star_number: 4,
          detail: 'comment_1',
        },
        {
          author_img: 'pic_avatar_2',
          author_name: 'user_2',
          star_number: 5,
          detail: 'comment_2',
        },
        {
          author_img: 'pic_avatar_3',
          author_name: 'user_3',
          star_number: 4,
          detail: 'comment_3',
        },
      ],
      download_star: 'download_star',
      download_grey_star: 'download_grey_star',
    };
  },
};
</script>

<style scoped lang="less">
  .fifth-screen {
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 0.2rem;
    overflow: hidden;
    .title {
      margin: 0.9rem 0 0.8rem;
      font-size: 0.4rem;
      font-weight: 500;
      color: #1A1A1A;
      line-height: 0.56rem;
      text-align: center;
    }
    .comment-list {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.4rem;
      .comment-item {
        width: 3.65rem;
        height: 3.2rem;
        margin-right: 0.28rem;
        padding: 0.4rem;
        background: #FFF4ED;
        border-radius: 0.16rem;
        .top-overview {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 0.24rem;
          .author-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            .author-img {
              height: 0.64rem;
              margin-bottom: 0.16rem;
            }
            .author-name {
              font-size: 0.16rem;
              font-weight: 500;
              color: #1A1A1A;
              line-height: 0.22rem;
            }
          }
          .star-box {
            .star-img, .grey-star-img {
              height: 0.2rem;
            }
          }
        }
        .comment-detail {
          font-size: 0.16rem;
          font-weight: 400;
          color: #1A1A1A;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          overflow: hidden;
        }
      }
      .comment-item:last-child {
        margin-right: 0;
      }
    }
  }
</style>
