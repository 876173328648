(function () {
  function flex() {
    const designSize = 1200;
    const minSize = 756;
    const html = document.documentElement;
    const vW = html.clientWidth;
    let rem;
    if (vW >= designSize) rem = 100;
    if (vW <= minSize) rem = (minSize * 100) / designSize;
    if (vW < designSize && vW > minSize) rem = (vW * 100) / designSize;
    html.style.fontSize = `${rem}px`;
  }
  // 页面加载完，加载一次
  flex();
  // 页面重新加载完，再执行一次 ???
  window.onload = flex;
  window.onresize = flex;
}());
