<template>
  <div class="first-screen">
    <div class="content">
      <h1 class="app-name">{{ $t(app_name) }}</h1>
      <p class="app-desc">{{ $t(app_description) }}</p>
      <div class="button-box">
        <button
          class="left-button button"
          :class="{ 'en-button': language === 'en' }"
          data-agl-cvt="6"
          @click="downloadDirectly">
          <img :src="$t(button_download)" alt="Download">
          <span class="button-text">{{ $t(free_download) }}</span>
        </button>
        <button
          class="right-button button"
          @click="jumpToAppStore">
          <img :src="$t(button_apple)" alt="Apple download">
          <span class="button-text">
            <span class="app-store-text">{{ $t(app_store_text) }}</span>
            <span class="download-text">{{ $t(download_text) }}</span>
          </span>
        </button>
      </div>
      <p class="app-detail">{{ $t(app_detail_1) }}{{ update_time }}{{ $t(app_detail_2) }}{{ size }}{{ $t(app_detail_3) }}{{ version }}{{ $t(app_detail_4) }}{{ os_version }}{{ $t(app_detail_5) }}</p>
    </div>
    <div class="video-box" :style="video_box_style">
      <video
        class="video"
        :src="$t(video)"
        ref="video"
        controls
        muted>
        {{ $t(video_text) }}
      </video>
    </div>
  </div>
</template>

<script>
import languageMixin from './mixin/languageMixin';
import jumpMixin from './mixin/jumpMixin';
import VersionDetail from '../../public/json/VersionDetail.json';

export default {
  name: 'FirstScreen',
  mixins: [languageMixin, jumpMixin],
  computed: {
    video_box_style() {
      return {
        'background-image': `url(${this.$t(this.computer)})`,
      };
    },
  },
  data() {
    return {
      app_name: 'zip_master',
      app_description: 'app_description',
      app_detail: 'app_detail',
      free_download: 'free_download',
      app_store_text: 'app_store',
      download_text: 'download',
      video_text: 'video_text',
      button_apple: 'button_apple',
      button_download: 'button_download',
      computer: 'computer',
      video: 'video',
      app_detail_1: 'app_detail_1',
      app_detail_2: 'app_detail_2',
      app_detail_3: 'app_detail_3',
      app_detail_4: 'app_detail_4',
      app_detail_5: 'app_detail_5',
      version: VersionDetail.version,
      size: VersionDetail.size,
      update_time: VersionDetail.update_time,
      os_version: VersionDetail.os_version,
    };
  },
  mounted() {
    window.onload = () => {
      const videoElement = this.$refs.video;
      videoElement.play();
    };
  },
};
</script>

<style scoped lang="less">
  .first-screen {
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    .content {
      margin: 1.32rem 0 0.6rem;
      text-align: center;
      .app-name {
        margin-bottom: 0.12rem;
        font-size: 0.56rem;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 0.78rem;
      }
      .app-desc {
        margin-bottom: 0.4rem;
        font-size: 0.21rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.29rem;
      }
      .button-box {
        display: flex;
        justify-content: center;
        margin-bottom: 0.16rem;
        .button {
          width: 2.22rem;
          height: 0.64rem;
          margin-right: 0.16rem;
          border-radius: 6px;
          cursor: pointer;
          /*img, .button-text {*/
          /*  vertical-align: middle;*/
          /*}*/
          img {
            height: 60%;
            margin-right: 0.18rem;
          }
          .button-text {
            font-size: 0.24rem;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 0.33rem;
          }
        }
        .left-button {
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: linear-gradient(to right, #FE552F, #FF8B37);
          img {
            width: 0.38rem;
            height: 0.38rem;
          }
        }
        .en-button {
          img {
            margin-right: 0.1rem;
          }
          .button-text {
            font-size: 0.21rem;
          }
        }
        .right-button {
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: linear-gradient(to right, #DADADA, #FFFFFF);
          img {
            height: 0.46rem;
            margin-right: 0.14rem;
          }
          .button-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: #1A1A1A;
            .app-store-text {
              font-size: 0.2rem;
              font-weight: 500;
              color: #1A1A1A;
              line-height: 0.28rem;
            }
            .download-text {
              font-size: 0.14rem;
              line-height: 0.2rem;
            }
          }
        }
      }
      .app-detail {
        font-size: 0.14rem;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
        line-height: 20px;
        white-space: pre-wrap;
      }
    }
    .video-box {
      position: relative;
      width: 9.7rem;
      height: 5.65rem;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      .video {
        position: absolute;
        top: 0.18rem;
        left: 1.12rem;
        width: 7.46rem;
        height: 4.67rem;
      }
    }
  }
</style>
