import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: '',
  },
  mutations: {
    setLanguage(state, payload) {
      state.language = payload.lang;
      document.title = state.language.indexOf('en') !== -1
        ? 'Zip Master - Free ZIP, UnZip & RAR software'
        : '解压大师 - 专为macOS设计的压缩软件';
    },
  },
  actions: {
  },
  modules: {
  },
});
