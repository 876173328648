<template>
  <div class="second-screen">
    <h1 class="title">{{ $t(title) }}</h1>
    <div class="zip-list">
      <div class="zip-item" v-for="(item, index) in zip_list" :key="item.text">
        <div class="item-wrap">
          <img class="zip-img" :src="$t(item.img)" alt="Zip image">
          <span class="zip-text">
            {{ index === zip_list.length - 1 ? $t(item.text) : item.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import img1 from '../../public/image/1@2x.png';
// import img2 from '../../public/image/1@2x(1).png';
// import img3 from '../../public/image/1@2x(2).png';
// import img4 from '../../public/image/1@2x(3).png';
// import img5 from '../../public/image/1@2x(4).png';
// import img6 from '../../public/image/1@2x(5).png';
// import img7 from '../../public/image/1@2x(6).png';
// import img8 from '../../public/image/1@2x(7).png';
// import img9 from '../../public/image/1@2x(8).png';

export default {
  name: 'SecondScreen',
  data() {
    return {
      title: 'unzip_format',
      zip_list: [
        {
          // img: img1,
          img: 'format_1',
          text: 'ZIP',
        },
        {
          // img: img2,
          img: 'format_2',
          text: '7Z',
        },
        {
          // img: img3,
          img: 'format_3',
          text: 'RAR',
        },
        {
          // img: img4,
          img: 'format_4',
          text: 'GZ',
        },
        {
          // img: img5,
          img: 'format_5',
          text: 'BZ',
        },
        {
          // img: img6,
          img: 'format_6',
          text: 'TGZ',
        },
        {
          // img: img7,
          img: 'format_7',
          text: 'TAR',
        },
        {
          // img: img8,
          img: 'format_8',
          text: 'BZ2',
        },
        {
          // img: img9,
          img: 'format_9',
          text: 'more_formats',
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
  .second-screen {
    max-width: 1200px;
    margin: 0 auto;
    /*padding: 0 0.2rem;*/
    overflow: hidden;
    .title {
      margin: 1.2rem 0 0.49rem;
      font-size: 0.4rem;
      font-weight: 500;
      color: #1A1A1A;
      line-height: 0.56rem;
      text-align: center;
    }
    .zip-list {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      /*max-width: 1200px;*/
      margin: 0 -0.15rem 1.05rem;
      .zip-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 2.08rem;
        height: 2.08rem;
        margin: 0.15rem;
        background-color: #FFFFFF;
        border-radius: 0.16rem;
        border: 1px solid #EFF0F2;;
        .item-wrap {
          text-align: center;
        }
        .zip-img {
          display: block;
          width: 0.88rem;
          margin: 0 auto 0.12rem;
        }
        .zip-text {
          font-size: 0.18rem;
          font-weight: 500;
          color: #1A1A1A;
          line-height: 0.25rem;
        }
      }
    }
  }
</style>
